
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>welcome to 7rays</h1>

      
      
    </div>
  );
}

export default App;
